import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";
import window from 'global'

// export default Page

const Index = (props) => {
   return (
      <>
         <Helmet
          title="蛤？養植物也是公共藝術嗎？ / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
          meta={[
            {
              name: `description`,
              content: "現代人的生活往往被工作填滿。在冷硬的鋼筋混凝土建築，和一條又一條灰色的巷道間移動，誰能記得上次與自然親近的時刻？難道我們與自然的關係，只能夠如此嗎？",
            },
            {
              property: `og:title`,
              content: "蛤？養植物也是公共藝術嗎？ / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            {
              property: `og:description`,
              content: "現代人的生活往往被工作填滿。在冷硬的鋼筋混凝土建築，和一條又一條灰色的巷道間移動，誰能記得上次與自然親近的時刻？難道我們與自然的關係，只能夠如此嗎？",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../images/discourse_b-1.jpg')
            }
          ]}
         >
         </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;